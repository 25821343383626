import React from "react";

import MainLayout from "../layouts/MainLayout";

import ollie from "../assets/images/ollie.png";
import gwen from "../assets/images/gwen.jpg";
import valerie from "../assets/images/valerie.jpg";
import bones from "../assets/images/bones.png";

const organizers = [
  {
    name: "Ollie Davis",
    pronouns: "they/them",
    role: "Operations & Project Management",
    img: ollie,
    href: "https://cubecobra.com/user/view/beeks",
  },
  {
    name: "Gwen Dekker",
    pronouns: "he/him",
    role: "Tech & Finance",
    img: gwen,
    href: "https://cubecobra.com/user/view/dekkerglen",
  },
  {
    name: "Valerie Mountes",
    pronouns: "she/her",
    role: "Communications",
    img: valerie,
    href: "https://bsky.app/profile/valeriefatality.bsky.social",
  },
  {
    name: `Austin "Bones" Hale`,
    pronouns: "he/him",
    role: "Head Judge & Staffing",
    img: bones,
    href: "https://cubecobra.com/user/view/LuckyLooter",
  },
];

const AboutPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-4xl font-bold text-center">
          The Team Behind Boston Cube Party
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {organizers.map((organizer) => (
            <div
              key={organizer.name}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <a href={organizer.href} target="_blank" rel="noreferrer">
                <img
                  src={organizer.img}
                  alt={organizer.name}
                  className="w-full h-80 object-cover"
                />
              </a>
              <div className="p-5">
                <div className="flex flex-row items-center gap-2">
                  <a href={organizer.href} target="_blank" rel="noreferrer">
                    <h2 className="text-xl font-bold text-rose-800 hover:underline">
                      {organizer.name}
                    </h2>
                  </a>
                  <p className="text-md">{organizer.pronouns}</p>
                </div>
                <p className="text-lg">{organizer.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default AboutPage;
