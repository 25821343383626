import React from "react";

import MainLayout from "../layouts/MainLayout";

const cubes2025 = [
  {
    name: "The Creatureless Cube",
    description:
      "This cube gives beloved (but powercrept or niche) cards a chance to shine once again with heavily synergistic, play-to-the-board gameplay.",
    id: "63e410ffb2e2394dc650e909",
    img: "https://cards.scryfall.io/art_crop/front/4/c/4c016fba-312d-43fc-92b6-212147b2f578.jpg?1630246886",
    returning: true,
  },
  {
    name: "Tiny Leaders",
    description:
      "This cube blends the synergistic reliability of a recastable card in the command zone with interactive small game cards.",
    id: "5d39e7f38472c42aab0b73d6",
    img: "https://cards.scryfall.io/art_crop/front/c/8/c8b432a7-53da-4480-b571-e6feb1364a3a.jpg?1562793427",
    returning: true,
  },
  {
    name: "Loam Cube",
    description:
      " Loam Cube is a desert cube where each player starts with a Life from the Loam in their graveyard. Come dredge, retrace, delve, cast some Goyfs, then win by drawing from an empty library.",
    id: "517cd182-38af-4a5f-a12e-50ced313078a",
    img: "https://cards.scryfall.io/art_crop/front/6/2/6200afaf-2720-488a-b760-e4d17d8aea79.jpg?1702416097",
    returning: true,
  },
  {
    name: "Invading DOM",
    description:
      "Invading Dom is a love letter to Dominaria. Each card is hand picked from one of our visits to the plane.",
    id: "610ad33d1956c010459983a4",
    img: "https://cards.scryfall.io/art_crop/front/3/5/356744f3-e444-4f4e-bf00-80bb6b2ef76f.jpg?1562905776",
    returning: true,
  },
  {
    name: "Cascade Cube",
    description:
      "Hello, friend! Let me ask you a question: how would you feel about starting your next game of Magic with Maelstrom Nexus on the battlefield?",
    id: "636be1066c3ec60f6637f150",
    img: "https://cards.scryfall.io/art_crop/front/4/2/425b3c5b-de65-46b2-b26d-347cc31beb4c.jpg?1562640901",
  },
  {
    name: "Bodleian Cube",
    description:
      "Bodleian Cube is a high synergy, eternal cube which rewards creativity in drafting and deckbuilding. Iconic cards and strategies that build an engine coexist in this cube.",
    id: "61454789685c83106293be3c",
    img: "https://cards.scryfall.io/art_crop/front/3/0/3009ba46-c9f8-46dc-8ffc-2aa4cef7b17c.jpg?1623779988",
    returning: true,
  },
  {
    name: "Companion Cube",
    description:
      "In Companion Cube, You may play any card in your pool, including lands, as though it has companion, according to the original, pre-errata companion rules.",
    id: "b67c9d5e-5cf5-46f6-bb3d-58a014df6a1a",
    img: "https://cards.scryfall.io/art_crop/front/4/b/4b32771b-d89f-46b1-8c70-95a4f058ef05.jpg?1721853465",
  },
  {
    name: "The Penrose Cube",
    description:
      "This cube focuses on synergy, graveyard strategies, and a color imbalance favoring black mana, followed by red and blue.",
    id: "636e940b282cc10f6a181e75",
    img: "https://cards.scryfall.io/art_crop/front/4/c/4cf9e9a9-325a-4010-acb8-1406adcaeca9.jpg?1562596403",
  },
  {
    name: "Regular Cube",
    description:
      "This cube focuses on good clean Magic with no gimmicks! An emphasis on creature focused strategies with cards chosen to be easy to understand makes this one fun for all skill levels.",
    id: "5dc09316845516168633e492",
    img: "https://cards.scryfall.io/art_crop/front/a/d/ad8b8518-c09e-4cb7-95b2-08e4e370d89c.jpg?1562803091",
  },
  {
    name: "A Brooding Saga",
    description:
      "This cube is built with a love of saga cards, with well thought out 3 color archetypes that will make you think a bit differently as you draft and play.",
    id: "549acfea-4793-41ca-b171-b7ae89a9903b",
    img: "https://cards.scryfall.io/art_crop/front/2/b/2b604451-4f6c-4cfa-8d3a-01f18d01d88f.jpg?1669836948",
  },
  {
    name: "555-JANK Cube",
    description:
      "This is a high power cube built for big games and exciting moments, using familiar staples and oddball redundancies!",
    id: "66212065-8632-43f4-a75c-230264b4b939",
    img: "https://cards.scryfall.io/art_crop/front/5/b/5b3847f1-5dda-463f-b898-ea2a879313a1.jpg?1562544993",
  },
  {
    name: "The Pauper Cube",
    description:
      "This cube has almost 2,000 followers on CubeCobra and is over 15 years old! Whether you’ve been curious about pauper cube or you’re already a huge pauper cuber, you won’t want to miss this one!",
    id: "5d617ac6c2a85f3b75fe95a4",
    img: "https://cards.scryfall.io/art_crop/front/4/f/4f3deefe-28bc-4e45-a0a0-ab03167e2e81.jpg?1561942156",
    returning: true,
  },
  {
    name: "Devoid Cube",
    description:
      "Devoid Cube is a unique semi-desert environment where the basic land station contains only Wastes and all spells are colorless!",
    id: "5dc9a64553f3101ce8e1d08c",
    img: "https://cards.scryfall.io/art_crop/front/6/0/60682c00-c661-4a9d-8326-f3f014a04e3e.jpg?1562914528",
    returning: true,
  },
  {
    name: "Eiganjo Drift",
    description: `THIS CUBE IS ABOUT VEHICLES!

AT THE BEGINNING OF EACH GAME, EACH PLAYER CREATES A TAPPED MECH HANGAR. THIS MEANS ANY VEHICLE (OR PILOT) WITH A SINGLE COLORED MANA COST CAN BE PLAYED IN ANY DECK!`,
    id: "62028de443cd0c53e6e1d661",
    img: "https://cards.scryfall.io/art_crop/front/4/3/436ce901-6ac6-4f8c-8ff0-18103f2642b8.jpg?1576382229",
  },
  {
    name: "Peebs Cube",
    description:
      "Peebs Cube is a legacy cube with low curves and lots of interaction. But don't let your guard down, there's some spice, especially in artifact/graveyard decks.",
    id: "63cddcb1a3b5060f686c7d19",
    img: "https://cards.scryfall.io/art_crop/front/6/6/66392169-5c6f-46bf-b0df-5670e40aecd9.jpg?1562913900",
    returning: true,
  },
  {
    name: "Doubleton Synergy",
    description:
      "A synergy cube with two of every card! The goal is to have ultra synergistic decks without breaking any of the rules of draft.",
    id: "f294ca48-7807-47e4-804d-7aa339216ae8",
    img: "https://cards.scryfall.io/art_crop/front/2/4/24dfa8eb-df44-4dcb-ad4f-4d548474eda6.jpg?1683835615",
  },
  {
    name: "Hoopin",
    id: "27227872-7676-4df8-bc04-4c32ddc7924d",
    description:
      "This is a low-powered 360 cube where all the cards are stickered to be dunking, dribbling, shooting, or holding a basketball, or are a basketball themselves.",
    img: "https://cards.scryfall.io/art_crop/front/9/1/91382955-bcfc-4fb6-8cce-dc107e5b4c32.jpg?1592710610",
  },
  {
    name: "Spicy Ramen Cube",
    id: "63322a647545331a605f8df1",
    img: "https://cards.scryfall.io/art_crop/front/d/8/d80100c3-c81e-4084-8dfe-f8610637fd91.jpg?1654567106",
    description:
      "Every card is around .35 cents, matching the average price of a pack of ramen, and the cube focuses on intense synergy over big splashy cards. It even comes with a menu!",
  },
  {
    name: "100 Ornithopters",
    id: "aa4ef3f9-0b4f-4bf0-9fbc-5cc3d3ec5e37",
    img: "https://cards.scryfall.io/art_crop/front/8/d/8d784214-dc69-42d4-b897-995ca5751e13.jpg?1562551442",
    description:
      "A perfectly normal Cube with one twist: the only creatures are 100 copies of the card Ornithopter.",
  },
  {
    name: "Delver Cube",
    id: "50d29893-1696-494d-86f1-d48615d160bd",
    img: "https://cards.scryfall.io/art_crop/front/8/8/888fbfaf-dbf9-4045-a79e-d436ef75b3cf.jpg?1692649173",
    description:
      "This cube is all about battling with low-curving, interactive tempo decks that play to the board.",
  },
  {
    name: "Aquaone Powered",
    id: "5fc9e578bada5f7f15feb582",
    img: "https://cards.scryfall.io/art_crop/front/8/2/82da0972-b17b-4600-9efd-e9430a0db04b.jpg?1559591414",
    description:
      "This cube embodies relationships with artists and a connection with the player community that most curators can only aspire to. Carefully curated in every sense, it is a draft experience like no other.",
  },
  {
    name: "(The Tried True) F360",
    id: "5d7a76ca26e6f46b5e4d5860",
    img: "https://cards.scryfall.io/art_crop/front/c/8/c8173030-1c33-417c-b8e9-79231b6a85a7.jpg?1562088796",
    description: `Full of artist signatures and built on "all the cards you love and none of the cards you don't." This unpowered eternal cube attempts to capture the ideal Magic experience!`,
  },
  {
    name: "Neon Aether 5.0",
    id: "487f57b4-9526-431c-93c7-7ba47ca3d008",
    img: "https://cards.scryfall.io/art_crop/front/4/0/40b13321-98f1-4e8c-802d-65498e43ec24.jpg?1717011816",
    description: `Described as "A Techno-Phyrexian, Cyber-Fantasy, Etherium-Pilled limited environment." This cube is all about energy and artifacts!`,
  },
  {
    name: "Budget Synergy Cube",
    id: "5332715a-73bb-4041-96a3-1bf15f7b0ce2",
    img: "https://cards.scryfall.io/art_crop/front/2/0/2047bfa4-3f4d-47bd-9484-545686f15b75.jpg?1608908659",
    description: `This cube is built from cards that are 3 bucks or less and features powerful synergies for all 10 color pairs with a few cards that allow for 3 color stategies.`,
    returning: true,
  },
  {
    name: "The Fire Swamp",
    id: "0870c9d6-b66e-4647-beda-6f1942b672b9",
    img: "https://cards.scryfall.io/art_crop/front/0/4/04013bc6-7774-400b-a6af-de755b4c324d.jpg?1562595903",
    description: `This cube is trying to kill you. Cards that are understatted and demand extra resources. Despite being a Jund cube, only swamps are available in the land box, meaning you'll have to draft your color sources.`,
  },
  {
    name: "Profoundly Peasant Cube",
    id: "613bee1f5af537103e15d895",
    img: "https://cards.scryfall.io/art_crop/front/f/e/fe2ddb54-c111-4612-aba5-b5e87b888251.jpg?1562640156",
    description: `An all-star cast of commons and uncommons that mainly focuses on 2 color archetypes and turning creatures sideways!`,
  },
  {
    name: "Ixalan-M20 Standard Cube",
    id: "61a116c5db49f110309fc0d3",
    img: "https://cards.scryfall.io/art_crop/front/7/8/787de9ce-02c5-4a17-a88b-d38e83dbeb0b.jpg?1572893092",
    description: `Go back in time, with a little effort! A look at some of what the standard format had to offer before Eldraine happened to it.`,
    returning: true,
  },
  {
    name: "Esper Cube",
    id: "b2c27903-e5ca-45a7-b264-2239c18217cb",
    img: "https://cards.scryfall.io/art_crop/front/a/3/a3d70a07-8d91-462c-aa96-901cc9a81531.jpg?1562707353",
    description: `This cube has a ton of themes under the esper umbrella and highlights a lot of what these colors can do.`,
  },
  {
    name: "Midwinter",
    id: "a92f4bd1-b3d5-4469-8577-905041301dc4",
    img: "https://cards.scryfall.io/art_crop/front/3/5/35ebe245-ebb5-493c-b9c1-56fbfda9bd66.jpg?1631053148",
    description: `This is a snow desert Viking/Arthurian themed cube designed to draft like a Masters set and play like kitchen table decks. Grab some mead and a warm coat!`,
  },
  {
    name: "Turbo Cube",
    id: "5f5d768ced6023105164a65f",
    img: "https://cards.scryfall.io/art_crop/front/f/b/fb988572-1e1d-4b1d-8dc7-78bda966554e.jpg?1562942414",
    description: `Everyone starts with an emblem that makes all spells and activated abilities cost 2 less. This cube is drafted in 4 packs of 13. Think fast!`,
  },
  {
    name: "Sacred Geometry",
    id: "3c8379e4-dcf7-45da-9cd3-ad3e12592304",
    img: "https://cards.scryfall.io/art_crop/front/1/a/1a2e41e3-919c-4bd3-9eea-adc0291f5f8a.jpg?1562544422",
    description: `Card choices are based on elegance of design. Some design choices in this cube include: Cards with correct oracle text printings only and no two tokens with the same color, p/t and rules text.`,
  },
];

const cubes2024 = [
  {
    name: "The Creatureless Cube",
    description:
      "This cube gives beloved (but powercrept or niche) cards a chance to shine once again with heavily synergistic, play-to-the-board gameplay.",
    id: "63e410ffb2e2394dc650e909",
    img: "https://cards.scryfall.io/art_crop/front/4/c/4c016fba-312d-43fc-92b6-212147b2f578.jpg?1630246886",
  },
  {
    name: "Peebs Cube",
    description:
      "Peebs Cube is a legacy cube with low curves and lots of interaction. But don't let your guard down, there's some spice, especially in artifact/graveyard decks.",
    id: "63cddcb1a3b5060f686c7d19",
    img: "https://cards.scryfall.io/art_crop/front/6/6/66392169-5c6f-46bf-b0df-5670e40aecd9.jpg?1562913900",
  },
  {
    name: "Tiny Leaders",
    description:
      "This cube blends the synergistic reliability of a recastable card in the command zone with interactive small game cards.",
    id: "5d39e7f38472c42aab0b73d6",
    img: "https://cards.scryfall.io/art_crop/front/c/8/c8b432a7-53da-4480-b571-e6feb1364a3a.jpg?1562793427",
  },
  {
    name: "Invading DOM",
    description:
      "Invading Dom is a love letter to Dominaria. Each card is hand picked from one of our visits to the plane.",
    id: "610ad33d1956c010459983a4",
    img: "https://cards.scryfall.io/art_crop/front/3/5/356744f3-e444-4f4e-bf00-80bb6b2ef76f.jpg?1562905776",
  },
  {
    name: "SHADOW WIZARD MONEY GANG",
    description:
      "Our local group brings us a color-restricted cube with only swamps in the basic land box, and plenty of strong mono B and Bx cards to draft. Achieve greatness at all costs!",
    id: "634ad80f6a41630f683ef91b",
    img: "https://cards.scryfall.io/art_crop/front/5/a/5a2747ab-00c8-4f59-b9a6-54ff4e99f6c8.jpg?1562719284",
  },
  {
    name: "Loam Cube",
    description:
      " Loam Cube is a desert cube where each player starts with a Life from the Loam in their graveyard. Come dredge, retrace, delve, cast some Goyfs, then win by drawing from an empty library.",
    id: "517cd182-38af-4a5f-a12e-50ced313078a",
    img: "https://cards.scryfall.io/art_crop/front/6/2/6200afaf-2720-488a-b760-e4d17d8aea79.jpg?1702416097",
  },
  {
    name: "Sci-Fi Cube",
    description:
      "It is the distant future: the year 2000. The Forests are dead. Finally, robots rule the world. Sci-Fi Cube is an artifact-centric cube dripping with theme and sweet synergies!",
    id: "5ecd517c4022a8067a285177",
    img: "https://cards.scryfall.io/art_crop/front/c/6/c60174d6-1f9d-4870-b3db-34d6fcb3f6ab.jpg?1564535860",
  },
  {
    name: "Devoid Cube",
    description:
      "Devoid Cube is a unique semi-desert environment where the basic land station contains only Wastes and all spells are colorless!",
    id: "5dc9a64553f3101ce8e1d08c",
    img: "https://cards.scryfall.io/art_crop/front/6/0/60682c00-c661-4a9d-8326-f3f014a04e3e.jpg?1562914528",
  },
  {
    name: "Budget Synergy Cube",
    description:
      "This cube takes fan-favorite classic archetypes like izzet spells, rakdos sacrifice, and golgari self-mill and cranks the synergy up to 11!",
    id: "623220f59876560fe936f3b9",
    img: "https://cards.scryfall.io/art_crop/front/2/0/2047bfa4-3f4d-47bd-9484-545686f15b75.jpg?1608908659",
  },
  {
    name: "da cube z0ne",
    description:
      "Da cube z0ne is our group's most played cube. It's a highly refined blue-skewed vintage cube with lots of decision points and tight gameplay. ",
    id: "30cc9421-f719-41f3-a217-39f259c05e92",
    img: "https://cards.scryfall.io/art_crop/front/f/3/f3edb27c-4faa-464a-9bb1-fbe17013c022.jpg?1688130725",
  },
  {
    name: "540 DEREK'S CUBE",
    description: `"I built this cube after years of building cubes that attempt to control variance. I'm grateful to Matt Grenier for our friendship and his inspired view on games. A fluid and improvisational spectacle awaits you." - Derek`,
    id: "f783b2ba-03a3-4954-aef0-44a3b62a5104",
    img: "https://cards.scryfall.io/art_crop/front/f/a/fab4cd7e-b56f-4408-a0e9-c07e040cc38f.jpg?1562948100",
  },
  {
    name: "XLN-M20 Standard Cube",
    description:
      "Standard is great, but do you remember the last time it was this good? Relive that glorious epoch or experience it for the first time! Nexus of Fate not included.",
    id: "61a116c5db49f110309fc0d3",
    img: "https://cards.scryfall.io/art_crop/front/7/8/787de9ce-02c5-4a17-a88b-d38e83dbeb0b.jpg?1572893092",
  },
  {
    name: "The Pauper Cube",
    description:
      "This cube has almost 2,000 followers on CubeCobra and is over 15 years old! Whether you’ve been curious about pauper cube or you’re already a huge pauper cuber, you won’t want to miss this one!",
    id: "5d617ac6c2a85f3b75fe95a4",
    img: "https://cards.scryfall.io/art_crop/front/4/f/4f3deefe-28bc-4e45-a0a0-ab03167e2e81.jpg?1561942156",
  },
  {
    name: "Bodleian Cube",
    description:
      " Bodleian Cube is a high synergy, eternal cube which rewards creativity in drafting and deckbuilding. Iconic cards and strategies that build an engine coexist in this cube.",
    id: "61454789685c83106293be3c",
    img: "https://cards.scryfall.io/art_crop/front/3/0/3009ba46-c9f8-46dc-8ffc-2aa4cef7b17c.jpg?1623779988",
  },
  {
    name: "The Buildaround Cube",
    description:
      "This cube has incredibly deep drafting decisions. Each pack is seeded with a buildaround card and each player starts the draft with a cogwork librarian.",
    id: "638032857504190f6ada9d67",
    img: "https://cards.scryfall.io/art_crop/front/4/5/453de664-0f09-4772-a626-51d58d1173f3.jpg?1562864968",
  },
  {
    name: "The Altered Cube",
    description:
      "Only available for our top 8, the stunning Altered Cube is a sight to behold. The vast majority of the cards in this cube have been altered by hand by talented artists from all over the world.",
    id: "63f8e97f81e88359951591ee",
    img: "https://cards.scryfall.io/art_crop/front/0/f/0fee7b1f-e300-411a-957d-5253300c36cf.jpg?1562487458",
  },
];

const Cubes: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-4xl font-bold text-center">
          The Cubes of Boston Cube Party 2025
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {cubes2025.map((cube) => (
            <div
              key={cube.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <a
                href={`https://cubecobra.com/cube/overview/${cube.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={cube.img}
                  alt={cube.name}
                  className="w-full h-48 object-cover"
                />
              </a>
              <div className="p-5">
                <div className="flex flex-row items-end justify-between">
                  <a
                    href={`https://cubecobra.com/cube/overview/${cube.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h2 className="text-xl font-bold text-rose-800 hover:underline">
                      {cube.name}
                    </h2>
                  </a>
                  {cube.returning && (
                    <p className="text-md font-semibold italic text-rose-800">
                      Returning!
                    </p>
                  )}
                </div>
                <p className="text-lg">{cube.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-4xl font-bold text-center">2024 Cubes</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {cubes2024.map((cube) => (
            <div
              key={cube.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <a
                href={`https://cubecobra.com/cube/overview/${cube.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={cube.img}
                  alt={cube.name}
                  className="w-full h-48 object-cover"
                />
              </a>
              <div className="p-5">
                <a
                  href={`https://cubecobra.com/cube/overview/${cube.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-xl font-bold text-rose-800 hover:underline">
                    {cube.name}
                  </h2>
                </a>
                <p className="text-lg">{cube.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Cubes;
